import React from "react";
import FlatButton from "../Reusables/FlatButton";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import ReactGA from "react-ga";

const StyledWrapper = styled.div`
  .home-summary-cta {
    text-decoration: none;
    color: black;
  }

  .mission {
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }

  .subtext a {
    text-decoration: underline;
    font-size: 24px;
    font-weight: 800;
    color: black;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  .summary-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 43px;
    font-family: ${props => props.theme["primaryFont"]} !important;
    text-transform: uppercase;
  }
  @media (max-width: 1091px) {
    .row {
      display: flex;
      flex-direction: column;
    }
    .col-lg-6 {
      max-width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

const SummaryBlock = ({ summary }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-12 col-lg-6 flex-column justify-content-center padding-left-8 padding-right-8 mission order-2 order-md-1">
            <p className="subtext">
              <ReactMarkdown source={summary.Summary} />
            </p>
          </div>
          <div className="col-12 col-lg-6 order-1 order-md-1 d-flex padding-left-8 padding-right-8 justify-content-center align-items-start py-3">
            {summary.image && (
              <img
                className="img-fluid mx-auto"
                src={mainUrl + summary.image.url}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default SummaryBlock;
